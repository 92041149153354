import { tLessonPageColor, ILesson } from '@adeptlms/lingu-students-react-shared';

const DEFAULT_LESSON_BACKGROUND = '#8371ce';

const defaultBack = {
  gradient: 'linear-gradient(180deg, #9F57C1 0%, #371B6A 100%), #8371ce',
  background: '#371B6A'
};

const colorsGradientMap = new Map<
  tLessonPageColor | string | null,
  { background?: string; gradient?: string }
>([
  [
    '#00a5d7', // blue
    {
      gradient: 'linear-gradient(180deg, #318DA9 0%, #0C436A 100%), #00a5d7',
      background: '#0C436A'
    }
  ],
  [
    '#8371ce', // purple
    {
      gradient: 'linear-gradient(180deg, #9F57C1 0%, #371B6A 100%), #8371ce',
      background: '#371B6A'
    }
  ],
  [
    '#8dc63f', // green
    {
      gradient: 'linear-gradient(180deg, #638449 0%, #064928 100%), #8dc63f',
      background: '#064928'
    }
  ],
  [
    '#f7941e', // orange
    {
      gradient: 'linear-gradient(180deg, #CF6752 0%, #872924 100%), #f7941e',
      background: '#872924'
    }
  ],
  [
    '#eb2486', // pink
    {
      gradient: 'linear-gradient(180deg, #BF5883 0%, #730F4B 100%), #eb2486',
      background: '#730F4B'
    }
  ],
  [DEFAULT_LESSON_BACKGROUND, defaultBack],
  [null, defaultBack]
]);

function getBackgroundColorIfLoaded(
  color: ILesson['color'],
  loaded: boolean
): NonNullable<ReturnType<typeof colorsGradientMap.get>> {
  if (!loaded) return { background: undefined };

  return colorsGradientMap.get(color) ?? defaultBack;
}

export { getBackgroundColorIfLoaded, DEFAULT_LESSON_BACKGROUND };
